import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogListing from "../components/blog/bloglisting.component"

export const blogPostListQueryByTag = graphql`
  query blogPostListQueryByTag($tag: String, $skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { tags: { in: [$tag] } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          tags
          title
          publishDate(formatString: "DD MMMM, YYYY")
          blogImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          category {
            name
          }
          body {
            childMarkdownRemark {
              excerpt
              html
            }
          }
        }
      }
    }
  }
`

const BlogListTag = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges
  const { currentPage, numPages, link, categories, tags, tag } = pageContext

  return (
    <Layout>
      <SEO title={`Blog listing for tag - ${tag} | Page ${currentPage}`} />
      <BlogListing
        posts={posts}
        currentPage={currentPage}
        numPages={numPages}
        link={link}
        categories={categories}
        tags={tags}
      />
    </Layout>
  )
}

export default BlogListTag
