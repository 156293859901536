import React, { useState, useEffect } from "react"
import BlogSideBar from "./blogsidebar.component"
import { Link } from "gatsby"
import Img from "gatsby-image"

const BlogListing = ({
  posts,
  currentPage,
  numPages,
  link,
  categories,
  tags,
}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const [activePage, setActivePage] = useState(1)
  useEffect(() => {
    const { pathname } = window.location
    const pageNumber = pathname.substr(pathname.lastIndexOf("/") + 1)
    if (pageNumber > 0) {
      setActivePage(pageNumber)
    }
  }, [])

  return (
    <>
      <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title="Blog">
                <span>Blog</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link> / <span>Blog</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding pt-xs-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-12 col-lg-12 blog-post-hr">
                  {posts.map(post => (
                    <>
                      <div className="blog-post mb-45">
                        <div className="post-media">
                          <div
                            id="blog-carousel"
                            className="owl-carousel blog-carousel item1-carousel nf-carousel-theme"
                          >
                            <div className="item">
                              <Img
                                fluid={post.node.blogImage.fluid}
                                alt={post.title}
                              />
                            </div>
                          </div>
                          <span className="event-calender blog-date">
                            {post.node.publishDate}
                          </span>
                        </div>
                        <div className="post-header">
                          <h2>
                            <Link to={`/blog/${post.node.slug}/`}>
                              {post.node.title}
                            </Link>
                          </h2>
                        </div>
                        <div className="post-entry">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                post.node.body.childMarkdownRemark.excerpt,
                            }}
                          ></div>
                        </div>
                        <div className="post-more-link pull-left">
                          <Link
                            className="btn-text"
                            to={`/blog/${post.node.slug}/`}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}
                </div>
              </div>
              {/* Pagination Nav */}
              <div className="pagination-nav text-left mt-60 mtb-xs-30">
                <ul>
                  {!isFirst && (
                    <li>
                      <Link to={`${link}${prevPage}`} rel="prev">
                        <i className="fa fa-angle-left" />
                      </Link>
                    </li>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      className={`${activePage === i + 1 ? "active" : ""}`}
                    >
                      <Link to={`${link}${i === 0 ? "" : i + 1}`}>{i + 1}</Link>
                    </li>
                  ))}
                  {!isLast && (
                    <li>
                      <Link to={`${link}${nextPage}`} rel="next">
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <BlogSideBar categories={categories} tags={tags} />
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogListing
